/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: system-ui;
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-fw-regular);
  color: var(--tg-body-color);
  line-height: var(--tg-body-line-height);
}



@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&amp;family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600&amp;display=swap");
:root {
  --tg-body-font-family: system-ui;
  --tg-heading-font-family: system-ui;
  --tg-icon-font-family: "Font Awesome 5 Free";
  --tg-body-font-size: 16px;
  --tg-body-line-height: 1.75;
  --tg-heading-line-height: 1.3;
  --tg-body-color: #6d6c80;
  --tg-heading-color: #161439;
  --tg-theme-primary: #5751e1;
  --tg-theme-secondary: #ffc224;
  --tg-common-color-blue: #050071;
  --tg-common-color-blue-2: #282568;
  --tg-common-color-indigo: #9b51e0;
  --tg-common-color-purple: #8121fb;
  --tg-common-color-pink: #ff429d;
  --tg-common-color-red: #e11b24;
  --tg-common-color-orange: #fd7e14;
  --tg-common-color-yellow: #f8bc24;
  --tg-common-color-yellow-2: #fbe67b;
  --tg-common-color-yellow-3: #fcb428;
  --tg-common-color-green: #12bb6a;
  --tg-common-color-teal: #219653;
  --tg-common-color-cyan: #00aee5;
  --tg-common-color-white: #fff;
  --tg-common-color-gray: #f7f7f9;
  --tg-common-color-gray-2: #efeefe;
  --tg-common-color-gray-3: #7f7e97;
  --tg-common-color-gray-4: #acaacc;
  --tg-common-color-gray-5: #b2bbcc;
  --tg-common-color-gray-6: #d7d7df;
  --tg-common-color-gray-7: #f6f6f6;
  --tg-common-color-gray-8: #f5f5f4;
  --tg-common-color-gray-9: #f9f9f9;
  --tg-common-color-gray-10: #f8f8f8;
  --tg-common-color-dark: #1c1a4a;
  --tg-common-color-black: #06042e;
  --tg-common-color-black-2: #161439;
  --tg-common-color-black-3: #000;
  --tg-border-1: #c9c9dd;
  --tg-border-2: #d0dae9;
  --tg-border-3: #e2e2e2;
  --tg-border-4: #d7dce3;
  --tg-border-5: #2f466a;
  --tg-border-6: #dfdfdf;
  --tg-fw-extra-bold: 800;
  --tg-fw-bold: 700;
  --tg-fw-semi-bold: 600;
  --tg-fw-medium: 500;
  --tg-fw-regular: 400;
  --tg-fw-light: 300;
}

/*=============================
	Typography css start
===============================*/
